<div class="d-flx flx-d-clm width-100 search-header-container bg-color-white">
    <div class="d-flx width-100 a-items-c j-cont-cen banner-container" *ngIf="config.bannerConfig?.show">
        <div class="show-desk">
            <p class="banner-label font-bold">{{ config.bannerConfig.label }}</p>
        </div>
        <div class="show-mob">
            <p class="banner-label font-bold">{{ config.bannerConfig.labelMob }}</p>
        </div>
    </div>
    <app-tabs
        *ngIf="headerTabsConfig.tabs.length"
        [config]="headerTabsConfig"
        (updateSelectedTab)="handleTabUpdate($event)"
    ></app-tabs>
    <ng-container [ngTemplateOutlet]="desktopHeader" *ngIf="!deviceInfo.isMobile"></ng-container>
    <ng-container *ngIf="deviceInfo.isMobile">
        <ng-container [ngTemplateOutlet]="unsupportedProductBanner" *ngIf="!isProductSupported"></ng-container>
        <ng-container [ngTemplateOutlet]="mobileHeader"></ng-container>
    </ng-container>
</div>

<!--templates-->
<ng-template #desktopHeader>
    <div
        appClickOutside
        (clickOutside)="handleClickOutside()"
        class="d-flx a-items-c width-100 input-container bg-color-white"
        [ngClass]="{
            'pv-8': !config.isExpanded && config.hideViewWorkspacesButtonInCollapsedView,
            'box-shadow': config.isExpanded,
        }"
    >
        <ng-container [ngTemplateOutlet]="citySelector"></ng-container>
        <div class="flx-1 search-typeahead-container">
            <app-typeahead
                (onFocus)="handleTypeaheadFocus()"
                (onSelect)="handleSearchItemSelect($event)"
                (onSearchStringChangeEvent)="handleSearchStringChange($event)"
                [config]="searchTypeaheadConfig"
                [optionsListTemplate]="searchContainerTemplate"
                [loadingTemplate]="searchLoadingTemplate"
                [noResultTemplate]="searchNoResultTemplate"
                [isSearchModalInFocus]="config.isExpanded"
                [initValue]="typeaheadInitValue || ''"
                [focusOnInput]="config.focusOnInit"
                (onRemoveSelectedItem)="handleRemoveSelectedItem($event)"
            ></app-typeahead>
        </div>
        <app-button
            [field]="viewWorkspacesBtnConfig"
            (onClick)="handleViewWorkspacesCTA()"
            [hidden]="config.hideViewWorkspacesButtonInCollapsedView && !config.isExpanded"
        ></app-button>
    </div>
</ng-template>
<ng-template #mobileHeader>
    <div class="d-flx flx-d-clm width-100 input-container">
        <div
            class="d-flx input-search-row-container j-cont-space-bet a-items-c width-100"
            [ngClass]="{ 'mb-0': config.hideViewWorkspacesButtonInCollapsedView }"
        >
            <ng-container [ngTemplateOutlet]="citySelector"></ng-container>
            <ng-container [ngTemplateOutlet]="staticInputElement"></ng-container>
        </div>
        <app-button
            [field]="viewWorkspacesBtnConfig"
            (onClick)="handleViewWorkspacesCTA()"
            [hidden]="config.hideViewWorkspacesButtonInCollapsedView"
        ></app-button>
    </div>
</ng-template>
<ng-template #citySelector>
    <div class="d-flx flx-d-clm city-selector-container c-pointer" (click)="handleCitySelectorClick($event)">
        <p class="city-selector-label text-color-medium-grey">City</p>
        <div class="d-flx a-items-c width-100 city-selector-value-container">
            <p class="city-selector-value text-color-dark text-ellipsis">{{ selectedCity?.name || 'Select City' }}</p>
            <span class="caret-down"></span>
        </div>
    </div>
</ng-template>

<ng-template #staticInputElement>
    <div class="d-flx a-items-c flx-1 static-input-container" (click)="handleTypeaheadFocus()">
        <p class="static-input-text font-12 text-ellipsis text-color-medium-grey">
            {{ searchTypeaheadConfig.placeholder }}
        </p>
    </div>
</ng-template>

<ng-template #unsupportedProductBanner>
    <div class="d-flx width-100 j-cont-cen unsupported-product-banner-wrapper">
        <app-search-unsupported-product-banner
            [config]="{ product: config.selectedProduct, city: selectedCity }"
        ></app-search-unsupported-product-banner>
    </div>
</ng-template>

<ng-template #tabItem let-tab="tab">
    <div
        class="d-flx flx-d-clm-row a-items-c j-cont-cen tab-item-container"
        [ngClass]="{ disabled: tabLabelDataMap[tab.label].comingSoon }"
    >
        <div
            class="d-flx a-self-cen a-items-c j-cont-cen tab-item-icon-container"
            [innerHTML]="tabLabelDataMap[tab.label].icon.url"
        ></div>
        <div class="d-flx flx-d-clm tab-item-text-container">
            <p class="text-color-dark tab-label">{{ tabLabelDataMap[tab.label].label }}</p>
            <p class="text-color-dark font-italic coming-soon-text" *ngIf="tabLabelDataMap[tab.label].comingSoon">
                Coming Soon!
            </p>
        </div>
    </div>
</ng-template>

<ng-template #searchContainerTemplate let-matches="matches" let-typeaheadTemplateMethods>
    <div class="padding-bottom-16 padding-horizontal-16 typeahead-tabs-cont" *ngIf="showOptionListWithTabs">
        <app-tabs [config]="optionListTabsConfig" (updateSelectedTab)="handleOptionTabUpdate($event)"></app-tabs>
    </div>
    <ng-container
        [ngTemplateOutlet]="searchListTemplate"
        [ngTemplateOutletContext]="{ matches: getFilteredMatches(matches), typeaheadTemplateMethods }"
    ></ng-container>
</ng-template>

<ng-template #searchListTemplate let-matches="matches" let-typeaheadTemplateMethods="typeaheadTemplateMethods">
    <div class="d-flx flx-d-clm overflow-y-auto">
        <div
            *ngFor="let match of matches"
            class="dropdown-item"
            [class.active]="typeaheadTemplateMethods.isActive(match)"
            (click)="typeaheadTemplateMethods.selectMatch(match, $event)"
            (mouseenter)="typeaheadTemplateMethods.selectActive(match)"
        >
            <app-search-typeahead-result-list [config]="match.item"></app-search-typeahead-result-list>
        </div>
    </div>
</ng-template>

<ng-template #searchLoadingTemplate>
    <app-search-typeahead-result-loading></app-search-typeahead-result-loading>
</ng-template>

<ng-template #searchNoResultTemplate>
    <app-search-typeahead-no-result></app-search-typeahead-no-result>
</ng-template>

<!-- Modal -->
@defer {
    <app-meeting-room-capacity-modal
        [open]="showMeetingRoomCapacityModal"
        [config]="meetingRoomCapacityModalConfig"
        (onClose)="toggleCapacityModal(false)"
    />
}
